<template>
  <mobile-screen
    :header="true"
    screen-class="resources-filter-screen gray-bg icon-app1"
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="resource-header icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: 'r_plan-resources-filters-help-online'
        }"
      >
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("search", "filters", "search-filters") }}
        </div>
      </top-header-menu-wrapper>
    </template>
    <section v-if="sections && sections.length">
      <ul
        v-for="section in sections"
        :key="section"
        class="clebex-item-section overflow-hidden"
      >
        <li
          class="clebex-item-section-item"
          :class="collapse[section] ? 'collapsed' : 'expanded'"
        >
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label larger-label">
                {{ displayLabelName("search", "users", section) }}
              </label>
            </div>
            <span
              class="follow-up-icons cursor-pointer"
              @click="
                collapse[section]
                  ? (collapse[section] = false)
                  : (collapse[section] = true)
              "
            >
              <span class="follow-up-icon-item collapsable"
                ><icon icon="#cx-app1-arrow-right-12x12" width="12" height="12"
              /></span>
            </span>
          </div>
        </li>
        <section
          v-if="section == 'options'"
          :class="collapse[section] ? 'collapsed' : 'expanded five'"
        >
          <li class="clebex-item-section-item no-bottom-border light">
            <div
              class="clebex-item-content-wrapper cursor-pointer"
              @click="selectFilter('sso', 1)"
            >
              <div class="clebex-section-input">
                <label class="clebex-section-input-label cursor-pointer">
                  {{ displayLabelName("search", "users", "sso") }}
                </label>
              </div>
              <span v-if="sso == 1" class="follow-up-icons">
                <span class="follow-up-icon-item">
                  <div class="svg-icon">
                    <svg
                      width="12"
                      height="12"
                      style="width: 12px; height: 12px;"
                    >
                      <use xlink:href="#cx-app1-checkmark"></use>
                    </svg>
                  </div>
                </span>
              </span>
            </div>
          </li>
          <li class="clebex-item-section-item no-bottom-border light">
            <div
              class="clebex-item-content-wrapper cursor-pointer"
              @click="selectFilter('visitor', 1)"
            >
              <div class="clebex-section-input">
                <label class="clebex-section-input-label cursor-pointer">
                  {{ displayLabelName("search", "users", "visitor") }}</label
                >
              </div>
              <span v-if="visitor == 1" class="follow-up-icons">
                <span class="follow-up-icon-item">
                  <div class="svg-icon">
                    <svg
                      width="12"
                      height="12"
                      style="width: 12px; height: 12px;"
                    >
                      <use xlink:href="#cx-app1-checkmark"></use>
                    </svg>
                  </div>
                </span>
              </span>
            </div>
          </li>
        </section>
        <section
          v-if="section == 'user-containers' && userFilters"
          :class="collapse[section] ? 'collapsed' : 'expanded five'"
        >
          <li
            v-for="userContainer in userFilters.userContainers"
            :key="userContainer.id"
            class="clebex-item-section-item no-bottom-border light"
          >
            <div
              class="clebex-item-content-wrapper cursor-pointer"
              @click="selectFilter('userContainers', userContainer.id)"
            >
              <div class="clebex-section-input">
                <label class="clebex-section-input-label cursor-pointer">{{
                  userContainer.name
                }}</label>
              </div>
              <span
                v-if="
                  selectedUserContainersIds.indexOf(userContainer.id) !== -1
                "
                class="follow-up-icons"
              >
                <span class="follow-up-icon-item">
                  <div class="svg-icon">
                    <svg
                      width="12"
                      height="12"
                      style="width: 12px; height: 12px;"
                    >
                      <use xlink:href="#cx-app1-checkmark"></use>
                    </svg>
                  </div>
                </span>
              </span>
            </div>
          </li>
        </section>
        <section
          v-if="section == 'assigned-resources' && userFilters"
          :class="collapse[section] ? 'collapsed' : 'expanded five'"
        >
          <li
            v-for="assignedResource in userFilters.assignedResources"
            :key="assignedResource.id"
            class="clebex-item-section-item no-bottom-border light"
          >
            <div
              class="clebex-item-content-wrapper cursor-pointer"
              @click="selectFilter('assignedResources', assignedResource.id)"
            >
              <div class="clebex-section-input">
                <label class="clebex-section-input-label cursor-pointer">{{
                  assignedResource.name
                }}</label>
              </div>
              <span
                v-if="
                  selectedAssignedResourcesIds.indexOf(assignedResource.id) !==
                    -1
                "
                class="follow-up-icons"
              >
                <span class="follow-up-icon-item">
                  <div class="svg-icon">
                    <svg
                      width="12"
                      height="12"
                      style="width: 12px; height: 12px;"
                    >
                      <use xlink:href="#cx-app1-checkmark"></use>
                    </svg>
                  </div>
                </span>
              </span>
            </div>
          </li>
        </section>
      </ul>
    </section>

    <template v-slot:footer>
      <nav class="actions-menu theme-gray">
        <ul class="actions-list">
          <li class="action">
            <button class="action-btn" @click="clearAll()">
              {{
                displayLabelName("resources", "resource-filters", "clear-all")
              }}
            </button>
          </li>
          <li class="action">
            <button
              class="action-btn"
              @click="
                setPaginationData(null);
                $store.commit(`${searchFiltersStore}/activateSearch`, true, {
                  root: true
                });
              "
            >
              {{ displayLabelName("resources", "resource-filters", "apply") }}
            </button>
          </li>
        </ul>
      </nav>
    </template>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";
import { getLang } from "@/services/http-service";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";

export default {
  name: "SearchFiltersUsers",
  mixins: [helpOnlineMixin],
  data() {
    return {
      collapse: {},
      helpSlug: "",
      sections: ["options", "user-containers", "assigned-resources"]
    };
  },
  created() {
    this.setPaginationData(null);
    this.getUserFilters();
  },
  computed: {
    locale() {
      return getLang();
    },
    userFilters() {
      return this.$store._state.data.user.userFilters;
    },
    ...mapState("user", ["userFilters"]),
    selectedUserContainersIds() {
      return this.$store.state[this.searchFiltersStore]
        .selectedUserContainersIds;
    },
    selectedAssignedResourcesIds() {
      return this.$store.state[this.searchFiltersStore]
        .selectedAssignedResourcesIds;
    },
    sso() {
      return this.$store.state[this.searchFiltersStore].sso;
    },
    visitor() {
      return this.$store.state[this.searchFiltersStore].visitor;
    },
    nonSearchableItemsHidden() {
      if (this.$route.name === "r_users-search-filters") {
        return false;
      } else {
        return true;
      }
    },
    ...mapState("search", ["searchQuery"])
  },
  methods: {
    ...mapActions("user", ["getUserFilters"]),
    ...mapActions("pagination", ["setPaginationData"]),
    setSelectedSection(section) {
      this.$store.dispatch(
        `${this.searchFiltersStore}/setSelectedSection`,
        section,
        { root: true }
      );
    },
    setSelectedUserContainersIds(id) {
      this.$store.dispatch(
        `${this.searchFiltersStore}/setSelectedUserContainersIds`,
        id,
        { root: true }
      );
    },
    setSelectedAssignedResourcesIds(id) {
      this.$store.dispatch(
        `${this.searchFiltersStore}/setSelectedAssignedResourcesIds`,
        id,
        { root: true }
      );
    },
    setSso() {
      this.$store.dispatch(`${this.searchFiltersStore}/setSso`, {
        root: true
      });
    },
    setVisitor() {
      this.$store.dispatch(`${this.searchFiltersStore}/setVisitor`, {
        root: true
      });
    },
    selectFilter(type, id) {
      if (type === "userContainers") {
        this.setSelectedUserContainersIds(id);
      } else if (type === "assignedResources") {
        this.setSelectedAssignedResourcesIds(id);
      } else if (type === "sso") {
        this.setSso();
      } else if (type === "visitor") {
        this.setVisitor();
      }
    },
    clearAll() {
      this.$store.commit(`${this.searchFiltersStore}/clearAllFilters`);
    }
  },
  watch: {
    searchQuery() {
      this.getUserFilters(this.payload);
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  props: {
    backLinkName: {
      type: String,
      required: false
    },
    searchFiltersStore: {
      type: String,
      required: true
    },
    disableModel: {
      type: Boolean,
      required: false
    }
  },
  beforeUnmount() {
    this.$store.commit("user/setUserFilters", null, {
      root: true
    });
  }
};
</script>
